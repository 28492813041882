import React from 'react'
import { ReactComponent as PicoIconSvg } from '../../assets/images/pico-logo.svg'
import SvgIcon from '@mui/material/SvgIcon'

type PicoIconProps = {
    fontSize?: string
    color?: string
}

const PicoIcon = ({ fontSize = '32px', color = 'figma.grayscale.tier1' }: PicoIconProps) => {
    return (
        <SvgIcon
            component={PicoIconSvg}
            inheritViewBox
            sx={{ fontSize, color }}
        />
    )
}

export default PicoIcon
