import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { alpha, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, Tabs, Typography } from '@mui/material'
import React, { ComponentProps } from 'react'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/arrow-left.svg'
import { ReactComponent as TableViewModeIcon } from '../../../../assets/images/ListDashes.svg'
import { ReactComponent as CardsViewModeIcon } from '../../../../assets/images/SquaresFour.svg'
import { ValueNarrativeCategory } from '../../queries/useGetValueNarrativesQuery'
import { EmptyPlaceholder } from '../../../../shared/components/EmptyPlaceholder'
import { useValueNarrativeCategoryColor } from '../../hooks/useValueNarrativeCategoryColor'
import { useDispatch, useSelector } from 'react-redux'
import { setValueNarrativesViewMode } from '../../../../redux/actions'
import TableRow from '@mui/material/TableRow'
import Tab from '@mui/material/Tab'

type Props = {
    categories: ValueNarrativeCategory[]
    handleClickOnValueNarrative: (categoryId: string, valueNarrativeId: string) => void
}

const DEFAULT_VIEW_MODE = 'cards'
export const TAB_HEIGHT = '42px'
export const TAB_WIDTH = '68px'

export const ValueNarrativesAllCategoriesTabContent = ({ handleClickOnValueNarrative, ...props }: Props) => {
    const viewMode = useSelector(
        // @ts-expect-error redux store is not typed properly
        (state) => state?.valueNarratives?.viewMode ?? DEFAULT_VIEW_MODE
    )

    const dispatch = useDispatch()

    const handleChangeViewMode = (_, newViewMode: 'cards' | 'table') => {
        dispatch(setValueNarrativesViewMode(newViewMode))
    }

    const { getCategoryColorByIndex } = useValueNarrativeCategoryColor()

    const categories = props.categories.filter((category) => category.narratives?.length > 0)

    if (!categories.length) {
        return (
            <Box pt={4}>
                <EmptyPlaceholder
                    title="No Value Narratives to display"
                    subtitle="Click 'Add New' on the left panel to create a new Value Narrative"
                />
            </Box>
        )
    }

    const tabProps: Partial<ComponentProps<typeof Tab>> = {
        sx: {
            height: TAB_HEIGHT,
            maxHeight: TAB_HEIGHT,
            minHeight: TAB_HEIGHT,
            width: TAB_WIDTH,
            maxWidth: TAB_WIDTH,
            minWidth: TAB_WIDTH,
            borderTop: '1px solid',
            borderTopColor: 'transparent !important',
            borderBottom: '1px solid',
            borderColor: 'figma.grayscale.stroke',
            fontFamily: 'acumin-pro !important',
            color: 'figma.grayscale.textHier2',
            paddingInline: '12px',
            paddingBlock: '4px',
            ' > div': {
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
            },
            '&.Mui-selected': {
                borderTopWidth: '2px',
                borderBottomWidth: '2px',
                borderColor: 'figma.primary',
                fontWeight: 600,
                lineHeight: '24px',
                ' > div': {
                    backgroundColor: 'figma.primary2',
                },
            },
        },
        wrapped: true,
        disableRipple: true,
    }

    // @TODO add ellipsis for long texts ?
    return (
        <>
            <Tabs
                value={viewMode}
                onChange={handleChangeViewMode}
                sx={{
                    height: TAB_HEIGHT,
                    maxHeight: TAB_HEIGHT,
                    minHeight: TAB_HEIGHT,
                    marginTop: '-' + TAB_HEIGHT,
                    marginBottom: '18px',
                    marginLeft: '-25px',
                    width: 'max-content',
                    minWidth: 'max-content',
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                }}>
                <Tab
                    label={
                        <div>
                            <SvgIcon
                                component={CardsViewModeIcon}
                                inheritViewBox
                                fontSize="small"
                                sx={{
                                    fontSize: '24px',
                                }}
                            />
                        </div>
                    }
                    value={'cards'}
                    {...tabProps}
                />
                <Tab
                    label={
                        <div>
                            <SvgIcon
                                component={TableViewModeIcon}
                                inheritViewBox
                                fontSize="small"
                                sx={{
                                    fontSize: '24px',
                                }}
                            />
                        </div>
                    }
                    value={'table'}
                    {...tabProps}
                />
            </Tabs>
            <Stack gap={1} p={viewMode === 'cards' ? '6px' : 0}>
                {categories.map((category, categoryIndex) => {
                    const color = getCategoryColorByIndex(categoryIndex)

                    if (viewMode === 'table') {
                        return (
                            <Box key={category._id}>
                                <TableContainer
                                    sx={{
                                        marginBottom: 3,
                                        '.MuiTableRow-root': {
                                            '&:nth-of-type(odd)': {
                                                backgroundColor: 'figma.grayscale.table1',
                                            },
                                            '&:nth-of-type(even)': {
                                                backgroundColor: 'figma.grayscale.table2',
                                            },
                                        },
                                        '.MuiTableCell-root': {
                                            border: 'none',
                                        },
                                    }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableCell />
                                            <TableCell>
                                                <Typography
                                                    mb={1.5}
                                                    color="figma.grayscale.textHier1"
                                                    fontSize="18px !important"
                                                    fontWeight={600}>
                                                    {category.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell />
                                        </TableHead>
                                        <TableBody>
                                            {category.narratives.map((narrative, narrativeIndex) => {
                                                const onValueNarrativeClick = () => {
                                                    handleClickOnValueNarrative(category._id, narrative._id)
                                                }

                                                return (
                                                    <TableRow key={narrative._id}>
                                                        <TableCell
                                                            align="left"
                                                            width={'32px'}
                                                            sx={{
                                                                borderTopLeftRadius: narrativeIndex === 0 ? 14 : 0,
                                                                borderBottomLeftRadius:
                                                                    narrativeIndex === category.narratives.length - 1
                                                                        ? 14
                                                                        : 0,
                                                                paddingRight: 0,
                                                            }}>
                                                            <Typography
                                                                // @ts-expect-error theme type
                                                                variant="body9"
                                                                color="figma.grayscale.tier3">
                                                                {narrativeIndex + 1}.
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" width="100%">
                                                            <Typography
                                                                // @ts-expect-error theme type
                                                                variant="body4"
                                                                color="figma.grayscale.textHier2">
                                                                {narrative.name}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            align="right"
                                                            sx={{
                                                                borderTopRightRadius: narrativeIndex === 0 ? 14 : 0,
                                                                borderBottomRightRadius:
                                                                    narrativeIndex === category.narratives.length - 1
                                                                        ? 14
                                                                        : 0,
                                                            }}>
                                                            <IconButton size="large" onClick={onValueNarrativeClick}>
                                                                <SvgIcon
                                                                    component={ArrowLeftIcon}
                                                                    inheritViewBox
                                                                    fontSize="small"
                                                                    color="primary"
                                                                    sx={{
                                                                        fontSize: '20px',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )
                    }

                    return (
                        <Box key={category._id}>
                            <Typography
                                mb={1}
                                color="figma.grayscale.textHier1"
                                fontSize="18px !important"
                                fontWeight={viewMode === 'table' ? 600 : 400}>
                                {category.name}
                            </Typography>
                            <Box
                                display="grid"
                                gridTemplateColumns={{
                                    sm: 'repeat(1, 1fr)',
                                    md: 'repeat(2, 1fr)',
                                    lg: 'repeat(3, 1fr)',
                                }}
                                gridAutoRows="minmax(80px, auto)"
                                gap={3}
                                pb={2}>
                                {category.narratives.map((narrative) => {
                                    const onValueNarrativeClick = () => {
                                        handleClickOnValueNarrative(category._id, narrative._id)
                                    }

                                    return (
                                        <Box
                                            key={narrative._id}
                                            p={2}
                                            pb={1.5}
                                            position="relative"
                                            sx={(theme) => ({
                                                backgroundColor: `${alpha(color, 0.1)} !important`,
                                                borderColor: color,
                                                borderRadius: '12px',
                                                borderStyle: 'solid',
                                                borderWidth: '1px',
                                            })}>
                                            <Typography
                                                mb={1}
                                                fontSize="16px !important"
                                                fontWeight={400}
                                                color="figma.grayscale.textHier2"
                                                sx={{
                                                    paddingRight: 6,
                                                    overflow: 'auto',
                                                    maxHeight: '350px',
                                                }}>
                                                {narrative.name}
                                            </Typography>
                                            <Box position="absolute" bottom="12px" right="12px">
                                                <IconButton size="small" onClick={onValueNarrativeClick}>
                                                    <SvgIcon
                                                        component={ArrowLeftIcon}
                                                        inheritViewBox
                                                        fontSize="small"
                                                        color="primary"
                                                        sx={{
                                                            fontSize: '16px',
                                                        }}
                                                    />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    )
                })}
            </Stack>
        </>
    )
}
