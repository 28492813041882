import React, { ReactNode, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import PicoIcon from '../../icons/PicoIcon'
import { ClickAwayListener, Fade, Popper } from '@mui/material'
import Stack from '@mui/material/Stack'
import AtlasIcon from '../../icons/AtlasIcon'
import { useSelector } from 'react-redux'

type Module = 'atlas' | 'pico'
type ModuleConfig = { icon: ReactNode; path: string; hidden?: boolean }

// @TODO refactor it

const ModuleSelector = () => {
    const history = useHistory()

    // @ts-expect-error state is not typed
    const isPicoEnabled = useSelector((state) => state?.isPicoEnabled)

    const MODULES_CONFIG: Record<Module, ModuleConfig> = {
        atlas: {
            icon: <AtlasIcon />,
            path: '/',
        },
        pico: {
            hidden: !isPicoEnabled,
            icon: <PicoIcon />,
            path: '/pico',
        },
    } as const

    const visibleModules = (Object.keys(MODULES_CONFIG) as Module[]).filter((module) => !MODULES_CONFIG[module].hidden)
    const visibleModulesConfig = visibleModules.reduce((acc, module) => {
        if (!MODULES_CONFIG[module].hidden) {
            return {
                ...acc,
                [module]: MODULES_CONFIG[module],
            }
        }

        return acc
    }, {} as Record<Module, ModuleConfig>)

    const hasMultipleModules = visibleModules.length > 1

    const [selectedModule, setSelectedModule] = useState<Module>(visibleModules[0] as Module)

    useEffect(() => {
        if (visibleModulesConfig.pico && history.location.pathname.startsWith(visibleModulesConfig.pico.path)) {
            setSelectedModule('pico')
        } else {
            setSelectedModule('atlas')
        }
    }, [history.location.pathname, visibleModulesConfig.pico?.path])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const getModuleButton = (module: Module) => {
        const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
            if (hasMultipleModules && module === selectedModule) {
                handleToggle(event)
            } else {
                history.push(visibleModulesConfig[module].path)
            }
        }

        return (
            <IconButton
                onClick={handleOnClick}
                sx={{
                    paddingY: 0.5,
                    paddingX: 0.25,
                    borderRadius: '4px',
                    '&:hover': {
                        backgroundColor: hasMultipleModules ? 'figma.primary3' : 'transparent',
                    },
                }}>
                {visibleModulesConfig[module].icon}
            </IconButton>
        )
    }

    return (
        <Box>
            {getModuleButton(selectedModule)}
            {hasMultipleModules && (
                <Popper
                    disablePortal
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement="bottom"
                    sx={(theme) => ({ zIndex: theme.zIndex.drawer })}>
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={handleClose}>
                            <Fade {...TransitionProps} in={!!anchorEl} timeout={800} unmountOnExit mountOnEnter>
                                <Stack
                                    direction="column"
                                    mt={1}
                                    py={2}
                                    px={1}
                                    gap={3}
                                    boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.20)"
                                    borderRadius="12px"
                                    bgcolor="figma.grayscale.tier7">
                                    {visibleModules
                                        .filter((module) => module !== selectedModule)
                                        .map((module) => {
                                            return getModuleButton(module as Module)
                                        })}
                                </Stack>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            )}
        </Box>
    )
}

export default ModuleSelector
