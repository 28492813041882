import React, { ReactNode, useEffect } from 'react'
import { Box, Tabs as MUITabs, Tab as MUITab, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

export const TAB_HEIGHT = '42px'

type Tab = {
    id: string
    view: ReactNode
    label?: string
    disabled?: boolean
    sx?: SxProps<Theme>
}

type TabsProps = {
    tabs: Tab[]
    selectedTabId: string
    onTabChange: (id: string) => void
    sx?: SxProps<Theme>
    tabPanelSx?: SxProps<Theme>
}

const TabList = ({
    tabs,
    selectedTabId,
    onTabChange,
}: {
    tabs: Tab[]
    selectedTabId: string
    onTabChange: (id: string) => void
}) => (
    <MUITabs
        orientation="vertical"
        variant="scrollable"
        scrollButtons="auto"
        value={selectedTabId}
        onChange={(_, newValue) => onTabChange(newValue)}
        sx={{
            borderRight: 1,
            borderColor: 'figma.grayscale.stroke',
            width: 'max-content',
            minWidth: 'max-content',
            '& .MuiTabs-indicator': {
                display: 'none',
            },
        }}>
        {tabs.map((tab) => (
            <MUITab
                key={tab.id}
                value={tab.id}
                disabled={tab.disabled}
                label={
                    <div>
                        <span>{tab.label ?? tab.id}</span>
                    </div>
                }
                wrapped
                disableRipple
                sx={{
                    height: TAB_HEIGHT,
                    maxHeight: TAB_HEIGHT,
                    minHeight: TAB_HEIGHT,
                    borderTop: '1px solid',
                    borderTopColor: 'transparent !important',
                    borderBottom: '1px solid',
                    borderColor: 'figma.grayscale.stroke',
                    fontSize: '16px',
                    fontWeight: '400',
                    fontFamily: 'acumin-pro !important',
                    textTransform: 'capitalize',
                    color: 'figma.grayscale.textHier2',
                    minWidth: '220px',
                    maxWidth: '25vw',
                    paddingInline: '12px',
                    paddingBlock: '4px',
                    ' > div': {
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        ' > span': {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        },
                    },
                    '&.Mui-selected': {
                        borderTopWidth: '2px',
                        borderBottomWidth: '2px',
                        borderColor: 'figma.primary',
                        fontWeight: 600,
                        lineHeight: '24px',
                        ' > div': {
                            backgroundColor: 'figma.primary2',
                        },
                    },
                    '&.Mui-disabled': {
                        ' > div': {
                            ' > span': {
                                color: 'figma.grayscale.tier5',
                            },
                        },
                    },
                }}
            />
        ))}
    </MUITabs>
)

const TabPanel = ({ tabs, selectedTabId, tabPanelSx }: Omit<TabsProps, 'onTabChange'>) => {
    const selectedTab = tabs.find((tab) => tab.id === selectedTabId)

    if (!selectedTab) {
        return null
    }

    return (
        <Box flex={1}>
            <Box
                sx={[
                    ...(Array.isArray(tabPanelSx) ? tabPanelSx : [tabPanelSx]),
                    ...(Array.isArray(selectedTab.sx) ? selectedTab.sx : [selectedTab.sx]),
                ]}>
                {selectedTab.view}
            </Box>
        </Box>
    )
}

const Tabs = ({ tabs, selectedTabId, onTabChange, sx = [], tabPanelSx = [] }: TabsProps) => {
    useEffect(() => {
        const validSelectedTab = tabs.find((tab) => tab.id === selectedTabId && !tab.disabled)
        if (!validSelectedTab) {
            const firstNonDisabledTab = tabs.find((tab) => !tab.disabled)
            if (firstNonDisabledTab) {
                onTabChange(firstNonDisabledTab.id)
            }
        }
    }, [selectedTabId, tabs, onTabChange])

    return (
        <Box flex={1} display="flex" sx={sx}>
            <TabList tabs={tabs} selectedTabId={selectedTabId} onTabChange={onTabChange} />
            <TabPanel tabs={tabs} selectedTabId={selectedTabId} tabPanelSx={tabPanelSx} />
        </Box>
    )
}

export default Tabs
